var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('div',{staticClass:"items"},[_c('van-list',{staticClass:"main-body",attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_vm._l((_vm.dataList),function(item,index){return [(item.type === 1)?_c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.$router.push({
              name: 'MediaArticle',
              query: { article_id: item.article_id },
            })}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(item.desc)+" ")]),_c('Analytics',{attrs:{"info":{
              view_count: item.view_count,
              forward_count: item.forward_count,
            }}})],1):_vm._e(),(item.type === 2)?_c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.$router.push({
              name: 'MediaPicture',
              query: { article_id: item.article_id },
            })}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))]),_c('PicGrid',{attrs:{"cover_img":item.cover_img}}),_c('Analytics',{attrs:{"info":{
              view_count: item.view_count,
              forward_count: item.forward_count,
            }}})],1):_vm._e(),(item.type === 3)?_c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.$router.push({
              name: 'MediaVideo',
              query: { article_id: item.article_id },
            })}}},[_c('div',{staticClass:"video-box"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"title"},[_c('van-tag',{staticClass:"tag",attrs:{"plain":"","type":"primary"}},[_vm._v("视频")]),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(item.desc)+" ")])]),_c('van-image',{staticClass:"img",attrs:{"fit":"contain","width":"100","height":"100","src":"https://img13.360buyimg.com/ddimg/jfs/t1/158292/8/987/2605/5fee4fe4E14a73fe3/75bd0ecf0141afcd.png"}})],1),_c('Analytics',{attrs:{"info":{
              view_count: item.view_count,
              forward_count: item.forward_count,
            }}})],1):_vm._e()]})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }